var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.manageBlacklists'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","to":{ name: 'main-blacklists-create' }}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.createBlacklist')))])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.blacklists,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"page":_vm.pagination.page,"footer-props":_vm.pagination.footerProps,"items-per-page":_vm.pagination.rowsPerPage,"server-items-length":_vm.total,"loading":_vm.loading,"show-select":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'main-blacklist-addresses-all', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.scheduled_dt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.scheduled_dt))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.text))])]}},{key:"item.is_tz_considered",fn:function(ref){
var item = ref.item;
return [_c('tr',[(item.is_tz_considered)?_c('v-icon',[_vm._v("checkmark")]):_vm._e()],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"justify-center layout px-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{ name: 'main-blacklists-edit', params: { id: item.id } }}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.deleteBlacklists(item.id)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.delete')))])])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" of "+_vm._s(itemsLength > _vm.pagination.rowsPerPage * _vm.pagination.page ? itemsLength - 1 + '+' : itemsLength)+" ")]}}])},[_c('v-progress-linear',{attrs:{"color":"blue","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }